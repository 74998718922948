var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-column" },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c("base-search", {
              staticStyle: { display: "inline-block" },
              attrs: {
                searchList: _vm.$jczlZbglSearch,
                listQuery: _vm.listQuery,
              },
              on: { search: _vm.handleFilter, "btn-event": _vm.onBtnClicked },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "app-container flex-item" }, [
        _c("div", { ref: "dragBox", staticClass: "dragBox" }, [
          _c(
            "div",
            { staticClass: "dragBox_left" },
            [
              _c(
                "el-card",
                {
                  staticClass: "body-small",
                  staticStyle: { height: "100%", overflow: "auto" },
                  attrs: { shadow: "never" },
                },
                [
                  _c("basic-tree", {
                    attrs: {
                      Treedata: _vm.orgsTree,
                      isShowCheck: true,
                      hasIcon: true,
                    },
                    on: {
                      handleNodeClick: _vm.handleNodeClick,
                      getCheckedKeys: _vm.getCheckedKeys,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dragBox_resize", attrs: { title: "收缩侧边栏" } },
            [_vm._v(" ⋮ ")]
          ),
          _c("div", { staticClass: "dragBox_mid" }, [
            _c(
              "div",
              {
                staticClass: "bg-white",
                staticStyle: { height: "100%" },
                attrs: { id: "table_box_height" },
              },
              [
                _c("basic-table", {
                  attrs: {
                    tableHeight: _vm.tableHeight,
                    tableHeaderList: _vm.firstHeaderList,
                    tableData: _vm.tableData,
                    listQuery: _vm.listQuery,
                    tableTotal: _vm.tableTotal,
                    hasSelection: true,
                  },
                  on: {
                    pagination: _vm.handleCurrentChange,
                    tableRowClick: _vm.tableRowClick,
                    handleSelectionChange: _vm.handleSelectionChange,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("general-form", {
        attrs: {
          FormDialog: _vm.FormDialog,
          FormList: _vm.FormList,
          title: _vm.addTitle,
          FormData: _vm.addform,
        },
        on: {
          cancelDialog: _vm.cancelDialog,
          confirmDialog: _vm.confirmDialog,
        },
      }),
      _c("general-form", {
        attrs: {
          FormDialog: _vm.FormDialogEdit,
          FormList: _vm.FormList,
          title: _vm.editTitle,
          FormData: _vm.modifyForm,
        },
        on: {
          cancelDialog: _vm.cancelDialogEdit,
          confirmDialog: _vm.confirmDialogEdit,
        },
      }),
      _c("general-form", {
        attrs: {
          FormDialog: _vm.areaChangeDialog,
          FormList: _vm.areaChangeList,
          title: "区域调整",
          FormData: _vm.areaChangeForm,
          isSpanAll: true,
          isAreaTree: true,
          chooseTotalMemterAreaData: _vm.mainWaterMeter,
        },
        on: {
          cancelDialog: _vm.cancelAreaChange,
          confirmDialog: _vm.confirmAreaChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }